import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import Layout from '../../components/layout';
import { HTMLContent } from '../../components/content';

export const PolicyPageTemplate = ({
  content,
  contentComponent,
  title,
}) => {
  const PostContent = contentComponent || HTMLContent;

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="policy-title">{title}</h1>
          <PostContent content={content} className="policy-content" />
        </Col>
      </Row>
    </Container>
  );
};

PolicyPageTemplate.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  contentComponent: PropTypes.elementType,
  title: PropTypes.string,
};

PolicyPageTemplate.defaultProps = {
  contentComponent: null,
  title: null,
};

// This is needed to encapsulate any <table> elements in a styled parent div so
// that any overflowing table content can be scrolled to.
const enhanceMarkdown = markdown => markdown
  .replace(/<table/g, '<div class="policy-table-container"><table')
  .replace(/<\/table>/g, '</table></div>');

const PolicyPage = ({ data }) => (
  <Layout>
    <PolicyPageTemplate
      content={enhanceMarkdown(data.content.html)}
      title={data.content.frontmatter.title}
    />
  </Layout>
);

PolicyPage.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default PolicyPage;

export const query = graphql`
query PolicyByPath($path: String!) {
  content: markdownRemark(
    frontmatter: { path: { eq: $path } }
  ) {
    html
    frontmatter {
      title
    }
  }
}
`;
